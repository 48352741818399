<template>
  <div>
    <general-table
      ref="offerCategory"
      :api-url="APIURL"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :view-content="viewContent"
      guard="merchant_types"
    >
      <template #cell(name)="data">
        {{ data.item.name_ar }}
      </template>
    </general-table>
    <div>
      <b-modal
        id="offerCategory"
        ref="genmodal"
        hide-footer
        centered
        no-close-on-backdrop
        title="Add Offer Category"
      >
        <add-edit-offer-category />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditOfferCategory from './control-offer-categories/AddEditOfferCategory.vue'

export default {
  components: { GeneralTable, AddEditOfferCategory },
  data() {
    return {
      APIURL: 'offer-categories',
      addType: 'Add New Category',
      type: 'modal',
      viewContent: false,
      columns: [
        { key: 'select' },
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'is_active', label: 'Active' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
}
</script>

<style>

</style>
