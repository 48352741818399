<template>
  <div>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name in English"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userForm.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name in English"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name in Arabic"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userForm.name_ar"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name in Arabic"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Active"
            label-for="business_type"
          >
            <b-form-checkbox
              v-model="userForm.is_active"
              checked="true"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addOfferCategories()"
          >
            Save
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'

export default {
  data() {
    return {
      id: this.$store.state.generalIds.id,
      loader: false,
      required,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const userForm = ref({
      name: '',
      is_active: '',
      name_ar: '',
    })
    return {
      getValidationState,
      userForm,
    }
  },
  mounted() {
    this.viewOfferCategories()
  },
  methods: {
    closeModal() {
      this.userForm = {
        name: '',
        is_active: '',
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('offerCategory')
    },
    addOfferCategories() {
      if (this.id) {
        axios.put(`offer-categories/${this.id}`, this.userForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.offerCategory.getAllData()
            this.closeModal()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
      } else {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.loader = true
            axios.post('offer-categories', this.userForm).then(res => {
              if (res.status === 200 || res.status === 201) {
                this.$parent.$parent.$parent.$refs.offerCategory.getAllData()
                this.closeModal()
                this.$toasted.show('Created Successfully', {
                  position: 'top-center',
                  duration: 3000,
                })
              }
            }).finally(() => {
              this.loader = false
            })
          }
        })
      }
    },
    viewOfferCategories() {
      if (this.id) {
        axios.get(`offer-categories/${this.id}`).then(res => {
          if (res.status === 200) {
            this.userForm = res.data?.data
          }
        })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
